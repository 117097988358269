<!--
 * @Description: 退款管理
 * @Author: 琢磨先生
 * @Date: 2022-05-18 11:44:11
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-05-31 15:53:24
-->
<template>
  <el-card class="box">
    <el-form :model="query" ref="query" :inline="true">
      <el-form-item label="订单号">
        <el-input v-model="query.q" placeholder="请输入订单号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search">查询</el-button>
      </el-form-item>
    </el-form>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-table :data="tableData.data" border stripe>
      <el-table-column label="编号" prop="id" width="80"></el-table-column>
      <el-table-column label="退款金额" width="120">
        <template #default="scope">
          <div>￥{{ scope.row.decimal_amount }}</div>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="status_text" width="120"></el-table-column>
      <el-table-column label="退款审核备注" prop="check_remark" width="200"></el-table-column>
      <el-table-column label="售后类型" prop="refund.type_text" width="120"></el-table-column>
      <el-table-column label="售后状态" prop="refund.status_text" width="120"></el-table-column>
      <el-table-column label="售后审核" prop="refund.check_emp_name" width="120"></el-table-column>
      <el-table-column label="售后审核备注" prop="refund.check_remark" width="200"></el-table-column>

      <el-table-column label="订单号" width="200">
        <template #default="scope">
          <el-link
            type="primary"
            :underline="false"
            @click="onDtl(scope.row)"
          >{{ scope.row.refund.order.order_no }}</el-link>
        </template>
      </el-table-column>
      <el-table-column label="商品标题" width="240" prop="goods_title"></el-table-column>
      <el-table-column label="数量" width="100">
        <template #default="scope">x{{ scope.row.refund.order.number }}</template>
      </el-table-column>
      <!-- <el-table-column label="商品金额" width="120">
        <template #default="scope">
          <div>￥{{ scope.row.order.decimal_amount }}</div>
        </template>
      </el-table-column>-->
      <el-table-column label="订单状态" width="120" prop="order.status_text"></el-table-column>
      <el-table-column label="创建时间" width="180" prop="create_at"></el-table-column>
      <el-table-column label="操作" fixed="right" width="180">
        <template #default="scope">
          <refund-check v-if="scope.row.status==0" :id="scope.row.id" @close="onSearch"></refund-check>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="tableData.counts > 0"
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>
  </el-card>
  <order-dtl :id="current_id" @close="current_id=null;"></order-dtl>
</template>

<script>
import RefundCheck from "./check.vue";
import OrderDtl from "../order/order_dtl.vue";

export default {
  components: {
    RefundCheck,
    OrderDtl,
  },
  data() {
    return {
      loading: false,
      enums: {},
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      //数据
      tableData: {
        counts: 0,
      },
      //订单id
      current_id: null,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.$http.post("seller/v1/mallRefund/pay", this.query).then((res) => {
        this.loading = false;
        if (res.code == 0) {
          this.tableData = res.data;
        }
      });
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 展开订单详情
     * @param {*} item
     */
    onDtl(item) {
      this.current_id = item.refund.order_id;
    },
  },
};
</script>

<style scoped>
</style>